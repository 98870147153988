import React, { FC } from 'react';

import { SplitView as SplitViewComponent } from '@components/SplitView/SplitView';
import { Placeholder } from '@sitecore/common';
import { SplitViewRendering } from '@sitecore/types/manual/SplitView';
import { Stack, Stretch } from '@sparky';

const SplitView: FC<SplitViewRendering> = ({ params }) => {
  const { bringToFront, backgroundFirst, backgroundSecond, columnArrangement, columnAlignment } = params ?? {};

  return (
    <SplitViewComponent columnArrangement={columnArrangement} columnAlignment={columnAlignment}>
      <SplitViewComponent.Item backgroundColor={backgroundFirst} bringToFront={bringToFront === 'first'}>
        <Stretch height>
          <Stack gap={6}>
            <Placeholder name="jss-split-view-first" />
          </Stack>
        </Stretch>
      </SplitViewComponent.Item>
      <SplitViewComponent.Item backgroundColor={backgroundSecond} bringToFront={bringToFront === 'second'}>
        <Stretch height>
          <Stack gap={6}>
            <Placeholder name="jss-split-view-second" />
          </Stack>
        </Stretch>
      </SplitViewComponent.Item>
    </SplitViewComponent>
  );
};

export default SplitView;
