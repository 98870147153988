import { FC, PropsWithChildren } from 'react';

import { SplitViewItemWrapper } from '@custom-components/SplitView';
import { Box, Grid, Stretch } from '@sparky';
import { BoxProps } from '@sparky/types';

import { useSplitView } from './SplitViewContext';
import { getGridProperties, getItemProperties } from './SplitViewProperties';

const StretchHeight: FC<PropsWithChildren> = ({ children }) => (
  <Stretch height={{ initial: true, sm: true, md: true, lg: false, xl: false }}>{children}</Stretch>
);

export const ItemBackground: FC<ItemBackgroundProps> = ({ order, backgroundColor }) => (
  <Grid.Item
    gridColumn={{
      initial: '1/-1',
      sm: '1/-1',
      md: '1/-1',
      ...(order === 1
        ? {
            lg: '1/9',
            xl: '1/8',
          }
        : {
            lg: '5/-1',
            xl: '6/-1',
          }),
    }}
    gridRow={{
      lg: '1/-1',
      xl: '1/-1',
      ...(order === 1
        ? {
            initial: '1/5',
            sm: '1/5',
            md: '1/5',
          }
        : {
            initial: '3/-1',
            sm: '3/-1',
            md: '3/-1',
          }),
    }}>
    <Stretch>
      {/** @ts-expect-error No Box children */}
      <Box backgroundColor={backgroundColor} />
    </Stretch>
  </Grid.Item>
);

export const SplitViewItem: FC<SplitViewItemProps> = ({ backgroundColor, bringToFront, children, order = 1 }) => {
  const { hasOverlap, columnArrangement, columnAlignment } = useSplitView();
  const { gridColumn, itemOffset } = getGridProperties(columnArrangement, columnAlignment);

  if (!hasOverlap && columnAlignment === 'full') {
    return (
      <Grid.Item gridColumn={gridColumn[order]} order={order}>
        <Stretch height>
          <Box paddingLeft={order === 2 ? itemOffset : undefined} paddingRight={order === 1 ? itemOffset : undefined}>
            <Stretch height>
              <Box backgroundColor={backgroundColor}>{children}</Box>
            </Stretch>
          </Box>
        </Stretch>
      </Grid.Item>
    );
  }

  if (!hasOverlap) {
    return (
      <Grid.Item gridColumn={gridColumn[order]} order={order}>
        <Box paddingLeft={order === 2 ? itemOffset : undefined} paddingRight={order === 1 ? itemOffset : undefined}>
          <Box backgroundColor={backgroundColor}>{children}</Box>
        </Box>
      </Grid.Item>
    );
  }

  const gridItemProps = getItemProperties(bringToFront)[order];

  if (bringToFront) {
    return (
      <Grid.Item {...gridItemProps} order={order}>
        <StretchHeight>
          <Box backgroundColor={backgroundColor}>
            <StretchHeight>
              <SplitViewItemWrapper>{children}</SplitViewItemWrapper>
            </StretchHeight>
          </Box>
        </StretchHeight>
      </Grid.Item>
    );
  }

  return (
    <>
      <ItemBackground backgroundColor={backgroundColor} order={order} />
      <Grid.Item {...gridItemProps}>
        <SplitViewItemWrapper>{children}</SplitViewItemWrapper>
      </Grid.Item>
    </>
  );
};

export type SplitViewItemOrder = 1 | 2;

interface SplitViewItemProps extends PropsWithChildren {
  /**
   * This prop will automatically be assigned a value
   */
  backgroundColor?: BoxProps['backgroundColor'];
  order?: SplitViewItemOrder;
  /**
   * Partially place this item on top of its sibling.
   */
  bringToFront?: boolean;
}

interface ItemBackgroundProps {
  order: SplitViewItemOrder;
  backgroundColor: SplitViewItemProps['backgroundColor'];
}
