import { BoxProps, GridItemProps, GridProps } from '@sparky/types';

import { ColumnAlignment, ColumnArrangement } from './SplitView';
import { SplitViewItemOrder } from './SplitViewItem';

const alignSelfDefault = 'center';
const alignSelfFront = { initial: 'stretch', sm: 'stretch', md: 'stretch', lg: alignSelfDefault, xl: alignSelfDefault };

const columnArrangementPresets = {
  '8-4': {
    first: {
      initial: '1 / -1',
      md: '1 / 8',
    },
    second: {
      initial: '1 / -1',
      md: '9 / -1',
    },
  },
  '4-8': {
    first: {
      initial: '1 / -1',
      md: '1 / 5',
    },
    second: {
      initial: '1 / -1',
      md: '6 / -1',
    },
  },
  '6-6': {
    first: {
      initial: '1 / -1',
      md: '1 / 7',
    },
    second: {
      initial: '1 / -1',
      md: '7 / -1',
    },
  },
};

export const getOverlappingGridProperties = (bringToFront?: SplitViewItemOrder) => {
  const gridTemplateRows = {
    initial: 'var(--space-10) min-content var(--space-12) var(--space-12) min-content var(--space-10)',
    sm: 'var(--space-10) min-content var(--space-12) var(--space-12) min-content var(--space-10)',
    md: 'var(--space-16) min-content var(--space-16) var(--space-16) min-content var(--space-16)',
    lg: 'var(--space-16) 1fr var(--space-16)',
    xl: 'var(--space-24) 1fr var(--space-24)',
  };

  const gridTemplateColumns = {
    initial: 'var(--space-6) repeat(10,1fr) var(--space-6)',
    sm: 'var(--space-6) repeat(10,1fr) var(--space-6)',
    md: 'var(--space-16) repeat(10,1fr) var(--space-16)',
    lg: 'repeat(4, 1fr) repeat(2, var(--space-16)) repeat(5, 1fr) var(--space-16)',
    xl: 'repeat(5, 1fr) repeat(2, var(--space-16)) repeat(4, 1fr) var(--space-24)',
  };

  if (bringToFront === 2) {
    Object.assign(gridTemplateColumns, {
      lg: 'var(--space-16) repeat(5, 1fr) repeat(2, var(--space-16)) repeat(6, 1fr)',
      xl: 'var(--space-24) repeat(4, 1fr) repeat(2, var(--space-16)) repeat(5, 1fr)',
    });
  }

  return {
    gridTemplateRows,
    gridTemplateColumns,
  };
};

export const getGridProperties = (columnArrangementParam?: ColumnArrangement, columnAlignment?: ColumnAlignment) => {
  const columnArrangement = columnArrangementParam?.includes('-') ? columnArrangementParam : '8-4';
  const [columnFirst, columnSecond] = columnArrangement.split('-');

  let gap: GridProps['gap'] = {
    initial: '6',
    sm: '6',
    md: '3',
    lg: '4',
    xl: '6',
  };
  let itemOffset: BoxProps['padding'];
  let gridColumnFirst = {
    initial: '1/-1',
    md: `span ${columnFirst}`,
  };
  let gridColumnSecond = {
    initial: '1/-1',
    md: `span ${columnSecond}`,
  };

  if (columnArrangement === '6-6') {
    gap = {
      initial: '6',
      sm: '6',
      md: '0',
      lg: '0',
      xl: '0',
    };
    itemOffset = {
      initial: '0',
      sm: '0',
      md: '8',
      lg: '12',
      xl: '16',
    };
  }

  if (columnArrangementPresets[columnArrangement]) {
    const { first, second } = columnArrangementPresets[columnArrangement];

    gridColumnFirst = first;
    gridColumnSecond = second;
  }

  let alignY: GridProps['alignY'] = 'center';
  if (columnAlignment === 'top') alignY = 'start';
  if (columnAlignment === 'full') alignY = 'stretch';

  return {
    gap,
    gridColumn: {
      1: gridColumnFirst,
      2: gridColumnSecond,
    },
    itemOffset,
    alignY,
  };
};

export const getItemProperties = (bringToFront?: boolean) => {
  const getFirstItemProperties = () => {
    const gridColumn = {
      initial: '2/-2',
      sm: '2/-2',
      md: '2/-2',
      lg: '2/7',
      xl: '2/6',
    };
    let gridRow: GridItemProps['gridRow'] = '2/3';
    let alignSelf: GridItemProps['alignSelf'] = alignSelfDefault;

    if (bringToFront) {
      Object.assign(gridColumn, { lg: '1/6', xl: '1/7' });

      gridRow = {
        initial: '1/4',
        sm: '1/4',
        md: '1/4',
        lg: '2/3',
        xl: '2/3',
      };

      alignSelf = alignSelfFront;
    }

    return {
      gridColumn,
      gridRow,
      alignSelf,
    };
  };

  const getSecondItemProperties = () => {
    const gridColumn = {
      initial: '2/-2',
      sm: '2/-2',
      md: '2/-2',
      lg: '7/-2',
      xl: '8/-2',
    };
    const gridRow: GridItemProps['gridRow'] = { initial: '5/-2', sm: '5/-2', md: '5/-2', lg: '2/3', xl: '2/3' };
    let alignSelf: GridItemProps['alignSelf'] = alignSelfDefault;

    if (bringToFront) {
      Object.assign(gridColumn, {
        lg: '8/-1',
        xl: '7/-1',
      });
      Object.assign(gridRow, { initial: '4/-1', sm: '4/-1', md: '4/-1' });
      alignSelf = alignSelfFront;
    }

    return {
      gridColumn,
      gridRow,
      alignSelf,
    };
  };

  return {
    '1': getFirstItemProperties(),
    '2': getSecondItemProperties(),
  };
};
